import { createMEDizzyHTTPClient } from '@medizzy/api';
import { createUnauthorizedCatcher } from '@medizzy/http-client';
import { redirect } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import { env } from '~/config/env';
import { useAccessToken } from '~/features/api/use-access-token';
import { ROUTES } from '~/utils/routes';

export function useProvideHttpClient() {
  const { data, refetch } = useAccessToken();
  const tokenRef = useRef(data?.accessToken);
  const clientRef = useRef(
    createMEDizzyHTTPClient({
      baseURL: env.VITE_API_URL,
    })
      .middlewares([
        (next) => (url, options) => {
          if (tokenRef.current) {
            return next(url, {
              ...options,
              headers: {
                'X-Auth-Token': tokenRef.current,
                ...options.headers,
              },
            });
          }
          return next(url, options);
        },
      ])
      .catcher(
        401,
        createUnauthorizedCatcher({
          refreshAuth: async () => {
            const { data } = await refetch();
            return data?.accessToken;
          },
          beforeResolve: ({ request, refreshAuthResult }) => {
            tokenRef.current = refreshAuthResult;
            return request;
          },
          onRetryUnauthorized: () => {
            throw redirect(ROUTES.AUTH.SIGN_OUT);
          },
        }),
      )
      .catcher(402, (error) => {
        window.dispatchEvent(new Event('medizzy:payment-required'));
        throw error;
      }),
  );

  useEffect(() => {
    if (tokenRef.current !== data?.accessToken) {
      tokenRef.current = data?.accessToken;
    }
  }, [data?.accessToken]);

  return { client: clientRef.current };
}
