import { useCreateCheckoutSession } from '@medizzy/payments';
import type { DialogProps } from '@radix-ui/react-dialog';
import { GraduationCapIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { toast } from 'sonner';
import { Button } from '~/components/button';
import {
  ResponsiveDialog,
  ResponsiveDialogBody,
  ResponsiveDialogClose,
  ResponsiveDialogContent,
  ResponsiveDialogFooter,
} from '~/components/responsive-dialog';
import { VStack } from '~/components/stack';
import { env } from '~/config/env';

export function LearningLimitDialog(props?: DialogProps) {
  const createCheckoutSession = useCreateCheckoutSession({
    onSuccess: ({ url }) => {
      location.replace(url);
    },
    onError: () => {
      toast.error('We could not create a checkout session. Please try again!');
    },
  });
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (props?.open) {
      trackEvent({ event: 'premium_popup_open' });
    }
  }, [props?.open, trackEvent]);

  return (
    <ResponsiveDialog dismissible={false} {...props}>
      <ResponsiveDialogContent
        withCloseButton={false}
        className="lg:max-w-[400px]"
      >
        <ResponsiveDialogBody>
          <VStack className="gap-4 justify-center items-center pt-8 pb-4">
            <div className="bg-amber-400 text-amber-950 p-5 rounded-xl">
              <GraduationCapIcon />
            </div>
            <h2 className="text-2xl font-bold text-center">
              Unlock more with Premium!
            </h2>
            <p className="text-center leading-relaxed">
              Dive deeper into your studies with access to exclusive questions
              and decks. Upgrade to a premium account and enhance your
              preparation today!
            </p>
            <VStack className="text-center justify-center items-center gap-2">
              <span className="text-xs text-muted-foreground">
                Starting from:
              </span>
              <span className="font-semibold">
                <span className="text-lg">$</span>
                <span className="text-3xl">
                  {env.VITE_APP === 'ems' ? 9.99 : 19.99}
                </span>
                <span className="text-xs"> Monthly</span>
              </span>
            </VStack>
          </VStack>
        </ResponsiveDialogBody>
        <ResponsiveDialogFooter>
          <VStack className="w-full gap-3">
            <Button
              isLoading={createCheckoutSession.isPending}
              className="flex-1"
              variant="premium"
              onClick={() => {
                trackEvent({ event: 'premium_popup_buy' });
                createCheckoutSession.mutate();
              }}
            >
              Upgrade
            </Button>
            <ResponsiveDialogClose asChild>
              <Button
                className="w-full"
                variant="ghost"
                onClick={() => {
                  trackEvent({ event: 'premium_popup_cancel' });
                }}
              >
                Cancel
              </Button>
            </ResponsiveDialogClose>
          </VStack>
        </ResponsiveDialogFooter>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
}
