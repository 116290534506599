import {
  Outlet,
  useFetchers,
  useNavigate,
  useNavigation,
  useRouteError,
  useSearchParams,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import './tailwind.css';
import { HTTPClientProvider } from '@medizzy/api';
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
  dehydrate,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useLearningGlobalMasteryLevel } from '@medizzy/learning';
import { useCurrentUser } from '@medizzy/user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { useTracking } from 'react-tracking';
import { useDehydratedState } from 'use-dehydrated-state';
import { useEventListener } from 'usehooks-ts';
import { env } from '~/config/env';
import { clientProxy } from '~/features/api/client-proxy';
import { useAccessToken } from '~/features/api/use-access-token';
import { useProvideHttpClient } from '~/features/api/use-provide-http-client';
import { useProvideQueryClient } from '~/features/api/use-provide-query-client';
import { LearningLimitDialog } from '~/features/learning/limit/learning-limit-dialog';
import { LearningRetakeDialog } from '~/features/learning/limit/learning-retake-dialog';
import { authenticator } from '~/services/auth.server';
import { themeSessionResolver } from '~/services/session.server';
import { ROUTES } from '~/utils/routes';
import { Layout } from './components/layout';
import { Toaster } from './components/sonner';

export async function loader({ request }: LoaderFunctionArgs) {
  const queryClient = new QueryClient();
  const session = await authenticator.isAuthenticated(request);
  const { getTheme } = await themeSessionResolver(request);

  if (session) {
    queryClient.setQueryData(useAccessToken.getQueryKey(), {
      accessToken: session.accessToken,
    });
  }

  return { dehydratedState: dehydrate(queryClient), theme: getTheme() };
}

export { Layout };

function AppWithHttpClient() {
  const { client } = useProvideHttpClient();
  clientProxy.client = client;

  return (
    <HTTPClientProvider client={client}>
      <ClarityUserInject />
      <PaymentRequiredListener />
      <Outlet />
    </HTTPClientProvider>
  );
}

export default function App() {
  const dehydratedState = useDehydratedState();
  const { queryClient } = useProvideQueryClient();
  const { Track } = useTracking({ env: env.VITE_APP });
  const navigation = useNavigation();
  const fetchers = useFetchers();

  /**
   * This gets the state of every fetcher active on the app and combine it with
   * the state of the global transition (Link and Form), then use them to
   * determine if the app is idle or if it's loading.
   * Here we consider both loading and submitting as loading.
   */
  const state = useMemo<'idle' | 'loading'>(
    function getGlobalState() {
      const states = [
        navigation.state,
        ...fetchers.map((fetcher) => fetcher.state),
      ];
      if (states.every((state) => state === 'idle')) return 'idle';
      return 'loading';
    },
    [navigation.state, fetchers],
  );

  useEffect(() => {
    NProgress.configure({ showSpinner: false });
  }, []);

  useEffect(() => {
    if (state === 'loading') {
      NProgress.start();
    }
    if (state === 'idle') {
      NProgress.done();
    }
  }, [state]);

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        <CookiesProvider>
          <Track>
            <AppWithHttpClient />
            <Toaster richColors position="top-center" />
            <ReactQueryDevtools initialIsOpen={false} />
          </Track>
        </CookiesProvider>
      </HydrationBoundary>
    </QueryClientProvider>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <div
      style={{
        padding: '16px',
        maxWidth: '400px',
        minHeight: '100vh',
        textAlign: 'center',
        fontFamily: 'Nunito',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src="/img/error.svg" alt="Error" style={{ maxWidth: '200px' }} />
      <h2>Whoops...</h2>
      <p style={{ lineHeight: 1.5 }}>
        We have encountered an unexpected error. It has been reported to our
        team. Use the button below to return to the app
      </p>
      <a
        href={ROUTES.DECKS.BROWSE}
        style={{
          display: 'block',
          background: 'black',
          padding: '16px',
          color: 'white',
          textDecoration: 'none',
          borderRadius: '8px',
        }}
      >
        Back to learning
      </a>
    </div>
  );
}

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `/img/favicon/${env.VITE_APP}.png`,
  },
  {
    rel: 'preload',
    as: 'image',
    href: `/img/landing/hero-desktop-${env.VITE_APP}-light.png`,
  },
  {
    rel: 'preload',
    as: 'image',
    href: `/img/landing/hero-desktop-${env.VITE_APP}-dark.png`,
  },
  {
    rel: 'preload',
    as: 'image',
    href: `/img/landing/hero-mobile-${env.VITE_APP}.png`,
  },
];

export const meta: MetaFunction = () => [
  {
    property: 'og:title',
    content: env.VITE_APP_NAME,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:description',
    content:
      'Unlock your potential with a powerful learning weapon available anytime, anywhere! Make sure you will pass your medical exams!',
  },
  {
    property: 'og:image',
    content: `/img/open-graph/${env.VITE_APP}.png`,
  },
  {
    property: 'og:image:width',
    content: 1200,
  },
  {
    property: 'og:image:height',
    content: 630,
  },
];

function ClarityUserInject() {
  const { data: user } = useCurrentUser();
  const [isUserSet, setIsUserSet] = useState(false);

  useEffect(() => {
    if (!user?.id || isUserSet) {
      return;
    }

    const id = setInterval(() => {
      if ('clarity' in window) {
        window.clarity('set', 'userId', user.id.toString());
        setIsUserSet(true);
      }
    }, 100);

    return () => {
      clearInterval(id);
    };
  }, [user?.id, isUserSet]);

  return null;
}

function PaymentRequiredListener() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLimitOpen, setIsLimitOpen] = useState(
    searchParams.get('showPremiumPopup') === 'true',
  );
  const [isRetakeOpen, setIsRetakeOpen] = useState(false);
  const { data: mastery } = useLearningGlobalMasteryLevel();
  const isRetakeEnabled = mastery?.mcq.incorrect !== 0;

  useEventListener(
    'medizzy:payment-required',
    useCallback(() => {
      setIsLimitOpen(true);
    }, []),
  );

  function onLimitDialogChange(isOpen: boolean) {
    if (isRetakeEnabled) {
      setIsRetakeOpen(!isOpen);
    } else {
      navigate(ROUTES.DECKS.BROWSE);
    }
    setIsLimitOpen(isOpen);
  }

  return (
    <>
      <LearningLimitDialog
        open={isLimitOpen}
        onOpenChange={onLimitDialogChange}
      />
      <LearningRetakeDialog
        open={isRetakeOpen}
        onOpenChange={setIsRetakeOpen}
      />
    </>
  );
}
